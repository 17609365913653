@import "../../styles/Colors.scss";

.collection_page_wrapper {
  // margin: 0px !important;
  // padding: 0px !important;

  .infinite-scroll-component {
    // overflow-y: hidden !important;
    // height: 100%;
  }

  .collection_page_outer {
    padding: 0px 20px 20px 20px;
    // max-width: 1366px;
    padding: 0px 10px !important;
    margin: 0 auto;
    // .breadCrumbs {
    //   align-items: center;

    //   .ant-breadcrumb {
    //     .ant-breadcrumb-link {
    //       a {
    //         color: #ababab !important;
    //       }
    //     }

    //     li:last-child {
    //       .ant-breadcrumb-link {
    //         a {
    //           color: $black !important;
    //         }
    //       }
    //     }
    //   }

    //   // background-color: #f8f8f8;

    //   display: flex;
    //   justify-content: space-between;
    //   margin-bottom: 10px;
    //   padding-bottom: 10px;
    //   width: 100%;
    // }
  }

  .collection_page_content {
    padding: 10px 20px;

    .header_border {
      border-bottom: 1px solid #edebef;
      //margin-bottom: 10px;

      span {
        color: $secondaryTwo;
        font-size: 12px;
      }
    }

    .mobile_header_border {
      border: 1px solid #edebef;
    }

    .ant-row {
      padding: 10px 0px;
      z-index: 2;
    }

    h4 {
      margin: 0px !important;
      font-size: 18px;
      font-weight: 300;
      color: $black;
    }

    .grid-show-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: $secondaryTwo;
        font-size: 12px;
      }

      .grid-icons-container {
        .ant-space-item {
          height: 20px;

          img {
            background: #f2f3f7 !important;
            cursor: pointer;
          }
        }
      }

      .ant-space {
        align-items: center;
      }
    }

    .filter_btn {
      background-color: #f2f3f7;
      padding: 5px 15px;
      border: none;
      //pointer-events: none;
      margin: 0px;
      opacity: 100;

      span {
        font-size: 14px;
        padding-left: 5px;
        // font-weight: 500;
        color: $filterOptionText;
      }

      svg {
        color: #000000;
        font-size: 10px;
      }
    }

    .ant-btn {
      &:hover,
      &:active,
      &:focus {
        background-color: #f2f3f7;
        outline: none;
        opacity: 80;
        color: #000000;
      }
    }

    .description_container {
      text-align: center;
      width: 100%;
      padding: 10px 0px;
      // display: flex;
      // justify-content: flex-start;

      span {
        color: $filterOptionText;

        .primary_red_color {
          color: $primary_red;
          font-weight: 600;
          cursor: pointer;
          font-size: 14px;
          font-family: "ZemestroStd-Light" !important;
          font-weight: 100 !important;
        }
      }
    }

    .mobile_description_container {
      padding: 10px 20px;
      color: #000;
    }

    .products_count_tag {
      color: $filterOptionText;
      font-size: 12px;
      font-weight: 100;
      font-family: "ZemestroStd-Regular" !important;

      span {
        color: $black;
        font-weight: 100;
      }
    }

    .load-more-btn {
      border-color: #ff0d50 !important;
      color: #ff0d50 !important;
      font-weight: 500;
      border-radius: 5px;
      padding: 0px 100px;
    }

    button {
      border-radius: 0px;
    }

    .ant-select-selector {
      // height: 24px !important;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #1e1e1e;

        // font-family: "ZemestroStd-SemiBold";
        // font-weight: 600;
      }
    }

    .sort_select_option {
      .ant-select-selector {
        .ant-select-selection-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }

    .mobile_select_option {
      .ant-select-selector {
        background-color: $background_color;
      }
    }
  }

  // .ant-select-selector {
  //   height: 10px;
  //   padding: 0px 6px !important;
  //   .ant-select-selection-item {
  //     font-size: 11px;
  //     padding: 0px !important;
  //   }
  //   .ant-select-arrow {
  //     svg {
  //       font-size: 11px !important;
  //     }
  //   }
  // }

  .product_listing_container {
    // min-height: 1600px;
    margin-bottom: 30px;
    justify-content: center;
    padding: 20px 0px;

    // .ProductCard,
    // .ProductHorizontalCard {
    //   padding: 10px !important;
    // }
    // gap: 20px;
    gap: 1%;
    // justify-content: space-between;
    // .grid5-product-card {
    //   .ant-card-meta-description {
    //     .ant-space-item {
    //       gap: 10px;
    //       .ant-typograph,
    //       .product_price {
    //         font-size: 13px !important;
    //       }
    //       del {
    //         font-size: 10px !important;
    //       }

    //       .ant-tag {
    //         padding: 0px 4px 0px 4px;
    //         margin: 0px;

    //         span {
    //           font-size: 10px;
    //           margin: 0px;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // .mobile_product_listing_container {
  //   .ProductCard,
  //   .ProductHorizontalCard {
  //     padding-right: 0px !important;
  //     padding-left: 0px !important;
  //   }
  // }
  .pagination_container {
    // margin-bottom: 30px;
    padding-top: 20px;

    .ant-pagination {
      li {
        background-color: #e4e4e4;
        border-radius: 0px;

        a,
        svg {
          color: #1e1e1e;
          font-size: 16px;
        }
      }

      // li:hover {
      //   background-color: #1e1e1e;
      //   color:$white
      // }
    }

    li.ant-pagination-item-active {
      border-color: #1e1e1e;
    }

    .ant-select {
      display: none;
    }

    button.ant-pagination-item-link {
      border-radius: 0px;
    }
  }

  .pagination_container_mobile {
    background-color: #ffffff;

    li {
      a,
      svg {
        font-size: 12px !important;
      }
    }
  }
}

.collection_page_wrapper {
  .ant-row {
    padding: 0px;
  }
}

// mz css
.seo-long-description {
  text-align: center;
  padding: 40px 0px;
  max-height: 400px;
  overflow-y: scroll;
}

body .collection-title {
  font-size: 18px;
  margin: 0;
  text-transform: capitalize;
  font-family: "ZemestroStd-Regular" !important;
  font-weight: 100 !important;
}

body .product-title {
  margin: 0;
  font-size: 28px !important;
  line-height: inherit;
  font-family: "ZemestroStd-Regular" !important;
  font-weight: 100 !important;
}

.collection-banner {
  padding-bottom: 20px;
}

.collection-banner img {
  width: 100%;
  object-fit: cover;
}

body .main-collection-inner {
  flex-direction: column;
}

// Alfa Mall Css MZ

.landing-page-content {
  max-width: 100%;
  margin-bottom: 15px;
}
body .collection_page_wrapper {
  // max-width: 1366px;
  padding: 0px 10px !important;
  margin: 0 auto;
  margin-top: 140px;
  background-color: #fff;
}
.landing_page_wrap {
  width: 98%;
  // padding: 0px 20px;
  margin: 130px auto 10px auto !important;
  background-color: #fff;
}
.collection-page-inner {
  display: flex;
}
.collection-page-left {
  max-width: 240px;
  min-width: 240px;
  margin-right: 20px;
}
.collection-page-right {
  flex-grow: 1;
}
body .breadCrumbs ol li span {
  color: #8b96a5;
  font-size: 16px;
}
.collection-page-left-inner .breadcrumb {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dedede;
}
.collection-page-header {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 20px 20px;
  border: 1px solid #dee2e7;
  border-radius: 6px;
  margin-bottom: 20px;
}
.collection-page-header-right {
  display: flex;
}
.collection-page-header-right .sort-by .sort_select_option {
  height: 40px;
}
.collection-page-header-right .sort-by .ant-select-selector {
  border-radius: 10px;
}
.collection-page-header-right .sort-by .ant-select-selection-item {
  color: #1c1c1c !important;
}
.sort-by {
  margin-right: 10px;
}
.grid-icons {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #dee2e7;
}
.grid-icons > div {
  width: 38px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.grid-icons > div.active {
  background: #dee2e7;
}
.collection-page-header-left span {
  font-weight: 900;
  font-family: "ZemestroStd-Medium";
}
.collection-page-selected-filters {
  margin-bottom: 30px;
}
.for-mobile {
  display: none !important;
}

.close-filter-drawer {
  background: #010035;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-filter-header .ant-drawer-title {
  text-align: center;
  font-size: 18px !important;
}
.mobile-filter-header .close-filterDrawer {
  width: 66px;
  height: 37px;
  background: var(--background-color);
  border: unset;
  border-radius: 10px;
  color: #fff;
  font-weight: 900;
}
.filter-type-category .ant-checkbox {
  display: none;
}
@media (max-width: 767px) {
  .landing_page_wrap {
    margin: 110px auto 10px auto !important;
  }
  .collection-page-selected-filters {
    margin-top: 115px;
  }
  .collection_page_wrapper .pagination_container_mobile {
    padding-bottom: 20px !important;
  }

  .collection-page-header-left {
    display: flex;
    flex-grow: 1;
  }

  .seo-long-description {
    text-align: center;
    padding: 40px 0px;
    max-height: 300px;
    overflow-y: scroll;
  }
  // Mz Alfa Css
  .collection-page-header {
    border: unset;
    height: unset;
    padding: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  .collection-page-header h3 {
    width: 100%;
  }
  .collection-page-header .sort-by {
    flex-grow: 1;
    display: flex;
    align-items: center;
    border: 1px solid #dee2e7;
    padding: 0px 10px;
    border-radius: 6px;
    height: 34px;
    margin-right: 5px;
    justify-content: space-between;
  }
  .collection-page-header .sort-by label {
    font-size: 13px;
    color: #1c1c1c;
    font-weight: 900;
    font-family: "ZemestroStd-Regular";
  }

  .collection-page-header
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: unset;
    padding: 0;
    padding-left: 5px;
  }
  .collection-page-header-left span {
    font-size: 13px;
  }
  .collection-page-header-left .ant-select .ant-select-arrow {
    display: none;
  }
  .collection-page-header-right {
    align-items: center;
  }
  .filter-selection-container label {
    font-size: 13px;
    font-weight: 900;
    font-family: "Inter-Regular";
  }
  .collection-page-header-right {
    align-items: center;
    flex-grow: 1;
  }

  .filter-selection-container {
    display: flex;
    align-items: center;
    height: 34px;
    border: 1px solid #dee2e7;
    margin-right: 5px;
    border-radius: 6px;
    flex-grow: 1;
    padding: 0px 10px;
    justify-content: space-between;
  }

  .filter-selection-container label {
    font-size: 13px;
    font-weight: 900;
    font-family: "Inter-Regular";
  }

  .grid-icons img {
    width: 20px;
  }
  body .mega-menu .collection_page_wrapper {
    margin-top: 100px;
    // margin-bottom: 65px;
  }
  .for-desktop {
    display: none !important;
  }
  .for-mobile {
    display: flex !important;
    position: fixed;
    z-index: 1;
    background-color: white;
    top: 90px;
  }
  .for-mobile .grid-icons > div {
    height: 32px;
  }
  .filters-container {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .filters-container .ant-tag {
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  body .filters-container .clear-all-button {
    font-size: 12px;
  }
  .filter-drawer .ant-checkbox-wrapper {
    margin-bottom: 10px;
  }
  body .ant-drawer .ant-drawer-header-title > button {
    display: none;
  }

  .ant-drawer .sort-drawer .ant-drawer-body {
    padding: 0px !important;
  }

  // .ant-drawer-content-wrapper {
  //   height: 320px !important;
  // }

  .radio-option {
    padding: 8px;
    padding-bottom: 10px;
    border-bottom: 1px solid #0505050f;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }

  .radio-option:last-of-type {
    border-bottom: none;
  }

  .radio-option .ant-radio {
    display: none;
  }

  .radio-checked {
    border-left: 5px solid var(--background-color);
  }

  .sort-icon {
    margin-right: 10px;
  }

  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: bold;
  }

  .drawer-title {
    text-align: center;
    flex: 1;
  }

  .close-icon {
    font-size: 20px;
    color: #000;
    cursor: pointer;
  }

  .drawer-body {
    padding: 0;
  }

  .radio-group-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: hidden;
  }
}
// @media screen and (max-width: 767px) {
//   .landing_page_wrap {
//     margin: 230px auto 10px auto !important;
//   }
// }
